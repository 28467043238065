import './contact.scss';
import {Button} from "../../components/Button";
import {environment} from "../../environment";

export interface ContactProps {}

export const Contact = (props: ContactProps) => {
	
	return (
		<div className="contactContainer">
			<div className="letsConnectContainer">
				<span onClick={() => {window.open(environment.maltUrl)}}>LET'S</span>
				<span onClick={() => {window.open(environment.maltUrl)}}>CONNECT</span>
			</div>
			<div className="skillsContainer">
				<Button text="REACT" handleClick={() => {return;}}></Button>
				<Button text="ANGULAR 2" handleClick={() => {return;}}></Button>
				<Button text="VUEJS" handleClick={() => {return;}}></Button>
				<Button text="NODEJS" handleClick={() => {return;}}></Button>
				<Button text="SPRINGBOOT" handleClick={() => {return;}}></Button>
				<Button text="GREAT CSS" handleClick={() => {return;}}></Button>
				<Button text="FOOTBALL" handleClick={() => {return;}}></Button>
				<Button text="DJING" handleClick={() => {return;}}></Button>
			</div>
		</div>
	);
};