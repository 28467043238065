import './description.scss';
import {Button} from "../../components/Button";
import { environment } from '../../environment';

export interface DescriptionProps {}

export const Description = (props: DescriptionProps) => {
	
	return (
		<div className="descriptionContainer">
			<div className="leftContainer">
				<div className="creative">creative</div>
				<div className="developer">developer</div>
			</div>
			<div className="rightContainer">
				<div className="desc">I AM A DEVELOPER BASED IN FRANCE.</div>
				<div className="desc">I HAVE WORKED IN FRANCE, SPAIN & CANADA.</div>
				<div className="desc">I AM JAVASCRIPT OPTIMIST COFFEE ADDICT</div>
				<div className="desc">AND MASSIVE MUSIC LOVER.</div>
				<div id="buttonContainer">
					<Button text="contact me" handleClick={() => {window.open(environment.maltUrl)}}/>
				</div>
			</div>
		</div>
	);
};