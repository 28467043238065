import './work-cities.scss';
import {RotatingStar} from "../../components/RotatingStar";
import madrid from '../../assets/img/madrid.jpeg';
import paris from '../../assets/img/paris.jpeg';
import montreal from '../../assets/img/montreal.jpeg';
import {Button} from "../../components/Button";
import React, {MutableRefObject, useRef, useState} from "react";
import {Property} from "csstype";
import {RotatingText} from "../../components/RotatingText";
import backArrow from '../../assets/img/back-arrow.svg';
import {mobileAndTabletCheck} from "../../utils";

export interface WorkCitiesProps {
    handlePictureOpening: (city) => any;
    handleOnClickSeeProjects: () => any;
}

export const WorkCities = (props: WorkCitiesProps) => {

    const madridRef = useRef<any>();
    const parisRef = useRef<any>();
    const montrealRef = useRef<any>();

    const [madridTranslateX, setMadridTranslateX] = useState(0);
    const [madridTranslateY, setMadridTranslateY] = useState(0);
    const [parisTranslateX, setParisTranslateX] = useState(0);
    const [parisTranslateY, setParisTranslateY] = useState(0);
    const [montrealTranslateX, setMontrealTranslateX] = useState(0);
    const [montrealTranslateY, setMontrealTranslateY] = useState(0);

    const [isInitialPosition, setIsInitialPosition] = useState(true);

    const [madridOpacity, setMadridOpacity] = useState(1);
    const [parisOpacity, setParisOpacity] = useState(1);
    const [montrealOpacity, setMontrealOpacity] = useState(1);

    const [pointEventsMadrid, setPointerEventsMadrid] = useState<Property.PointerEvents>('all');
    const [pointEventsParis, setPointerEventsParis] = useState<Property.PointerEvents>('all');
    const [pointEventsMontreal, setPointerEventsMontreal] = useState<Property.PointerEvents>('all');

    const [pointEventsallTexts, setPointerEventsAllTexts] = useState<Property.PointerEvents>('all');

    const [pointEventsBackArrows, setPointerEventsBackArrows] = useState(false);

    const [opacityAllTexts, setOpacityAllTexts] = useState(1);

    const [rotTextMadrid, setRotTextMadrid] = useState(false);
    const [rotTextParis, setRotTextParis] = useState(false);
    const [rotTextMontreal, setRotTextMontreal] = useState(false);

    const clickOpeningPicture = (ref: MutableRefObject<any>) => {
        console.log(ref)

        setIsInitialPosition((prev) => !prev);

        if (isInitialPosition) {

            setPointerEventsBackArrows(true);

            document.getElementById('paris').scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
            });

            setPointerEventsAllTexts('none');
            setOpacityAllTexts(0);

            if (ref.current.id === "madrid") {
                setParisOpacity(0);
                setMontrealOpacity(0);
                setMadridTranslateX(mobileAndTabletCheck() ? 0 : 20);
                setMadridTranslateY(mobileAndTabletCheck() ? 20 : 100);
                setPointerEventsParis('none');
                setPointerEventsMontreal('none');
                setRotTextMadrid(true);
            }

            if (ref.current.id === "paris") {
                setMadridOpacity(0);
                setMontrealOpacity(0);
                setParisTranslateX(mobileAndTabletCheck() ? 0 : -20);
                setParisTranslateY(mobileAndTabletCheck() ? -80 : 50);
                setPointerEventsMadrid('none');
                setPointerEventsMontreal('none');
                setRotTextParis(true);
            }

            if (ref.current.id === "montreal") {
                setMadridOpacity(0);
                setParisOpacity(0);
                setMontrealTranslateX(mobileAndTabletCheck() ? 0 : 20);
                setMontrealTranslateY(mobileAndTabletCheck() ? -180 : -100);
                setPointerEventsMadrid('none');
                setPointerEventsParis('none');
                setRotTextMontreal(true);
            }
        } else {
            if (ref.current.id === "madrid") {
                setMadridTranslateX(0);
                setMadridTranslateY(0);
            }

            if (ref.current.id === "paris") {
                setParisTranslateX(0);
                setParisTranslateY(0);
            }

            if (ref.current.id === "montreal") {
                setMontrealTranslateX(0);
                setMontrealTranslateY(0);
            }
            setMadridOpacity(1)
            setParisOpacity(1)
            setMontrealOpacity(1)
            setPointerEventsMadrid('all');
            setPointerEventsParis('all');
            setPointerEventsMontreal('all');
            setPointerEventsAllTexts('all');
            setOpacityAllTexts(1);
            setRotTextMadrid(false);
            setRotTextParis(false);
            setRotTextMontreal(false);
            setPointerEventsBackArrows(false);
        }

        props.handlePictureOpening(ref.current);
    }

    return (
        <div className="workCitiesContainer">
            <div className="titleContainer">
                <RotatingStar/>
                <h2>Cities I have worked in</h2>
            </div>
            <div className="mainContainer">
                <div className="citiesContainer">
                    <div className="img imgContainer madridImg" ref={madridRef} id="madrid" style={{
                        transition: 'all 0.8s', transitionDelay: '350ms',
                        transform: `translate(${madridTranslateX}%, ${madridTranslateY}%)`,
                        opacity: madridOpacity, pointerEvents: isInitialPosition ? pointEventsMadrid : 'none'
                    }}>
                        <div className={`grid-el ${rotTextMadrid ? 'active' : ''}`}>
                            <img onClick={() => clickOpeningPicture(madridRef)} src={madrid} alt=""/>
                        </div>
                    </div>
                    <div className="madridText" style={{
                        transition: 'all 0.8s',
                        transitionDelay: '350ms',
                        opacity: opacityAllTexts,
                        pointerEvents: pointEventsallTexts
                    }}>
                        <h3>Madrid</h3>
                        <div className="text">SOPRA STERIA</div>
                    </div>
                    <div className="parisText" style={{
                        transition: 'all 0.8s',
                        transitionDelay: '350ms',
                        opacity: opacityAllTexts,
                        pointerEvents: pointEventsallTexts
                    }}>
                        <h3>Paris</h3>
                        <div className="text">CACD2</div>
                    </div>
                    <div className="img imgContainer parisImg" ref={parisRef} id="paris" style={{
                        transition: 'all 0.8s',
                        transitionDelay: '350ms',
                        transform: `translate(${parisTranslateX}%, ${parisTranslateY}%)`,
                        opacity: parisOpacity,
                        pointerEvents: isInitialPosition ? pointEventsParis : 'none'
                    }}>
                        <div className={`grid-el ${rotTextParis ? 'active' : ''}`}>
                            <img onClick={() => clickOpeningPicture(parisRef)} src={paris} alt=""/>
                        </div>
                    </div>
                    <div className="img imgContainer montrealImg" ref={montrealRef} id="montreal" style={{
                        transition: 'all 0.8s',
                        transitionDelay: '350ms',
                        transform: `translate(${montrealTranslateX}%, ${montrealTranslateY}%)`,
                        opacity: montrealOpacity,
                        pointerEvents: isInitialPosition ? pointEventsMontreal : 'none'
                    }}>
                        <div className={`grid-el ${rotTextMontreal ? 'active' : ''}`}>
                            <img onClick={() => clickOpeningPicture(montrealRef)} src={montreal} alt=""/>
                        </div>
                    </div>
                    <div className="montrealText" style={{
                        transition: 'all 0.8s',
                        opacity: opacityAllTexts,
                        pointerEvents: pointEventsallTexts,
                        transitionDelay: '350ms',
                    }}>
                        <h3>Montréal</h3>
                        <div className="text">DESJARDINS</div>
                    </div>
                </div>
                <div
                    className="descriptionMadrid"
                    style={{
                    pointerEvents: pointEventsBackArrows ? pointEventsMadrid : 'none'
                }}>
                    <div>{rotTextMadrid}</div>
                    <img className={`arrow ${rotTextMadrid ? 'active' : ''}`} src={backArrow}
                         style={{transform: 'scale(-1,1)', opacity: rotTextMadrid ? 1 : 0}} alt=""
                         onClick={() => clickOpeningPicture(madridRef)}/>
                    <RotatingText text={'I WORKED IN MADRID FOR 6 MONTHS FOR A ESN CALLED '} font={'TG-Semibold'}
                                  isTextRendered={rotTextMadrid} delayIn={800} delayOut={0}
                                  fontSize={mobileAndTabletCheck() ? 24 : 48}/>
                    <RotatingText text={'SOPRA STERIA AS ASPRINGBOOT DEVELOPER &'} font={'TG-Semibold'}
                                  isTextRendered={rotTextMadrid} delayIn={800} delayOut={0}
                                  fontSize={mobileAndTabletCheck() ? 24 : 48}/>
                    <RotatingText text={'THAT WAS MY FIRST PROFESSIONAL EXPERIENCE'} font={'TG-Semibold'}
                                  isTextRendered={rotTextMadrid} delayIn={800} delayOut={0}
                                  fontSize={mobileAndTabletCheck() ? 24 : 48}/></div>
                <div
                    className="descriptionParis"
                    style={{
                    pointerEvents: pointEventsBackArrows ? pointEventsParis : 'none'
                }}>
                    <img className={`arrow ${rotTextParis ? 'active' : ''}`} src={backArrow}
                         style={{opacity: rotTextParis ? 1 : 0}} alt="" onClick={() => clickOpeningPicture(parisRef)}/>
                    <RotatingText text={'I WORKED IN PARIS FOR FOUR YEARS IN BANK'} font={'TG-Semibold'}
                                  isTextRendered={rotTextParis} delayIn={800} delayOut={0}
                                  fontSize={mobileAndTabletCheck() ? 24 : 48}/>
                    <RotatingText text={'CALLED CRÉDIT AGRICOLE AS A SPRINGBOOT, '} font={'TG-Semibold'}
                                  isTextRendered={rotTextParis} delayIn={800} delayOut={0}
                                  fontSize={mobileAndTabletCheck() ? 24 : 48}/>
                    <RotatingText text={'REACT NODEJS, ANGULAR & VUEJS DEVELOPER'} font={'TG-Semibold'}
                                  isTextRendered={rotTextParis} delayIn={800} delayOut={0}
                                  fontSize={mobileAndTabletCheck() ? 24 : 48}/></div>
                <div
                    className="descriptionMontreal"
                    style={{
                    pointerEvents: pointEventsBackArrows ? pointEventsMontreal : 'none'
                }}>
                    <img className={`arrow ${rotTextMontreal ? 'active' : ''}`} src={backArrow}
                         style={{transform: 'scale(-1,1)', opacity: rotTextMontreal ? 1 : 0}} alt=""
                         onClick={() => clickOpeningPicture(montrealRef)}/>
                    <RotatingText text={'I WORKED IN MONTREAL FOR ONE YEAR'} font={'TG-Semibold'}
                                  isTextRendered={rotTextMontreal} delayIn={800} delayOut={0}
                                  fontSize={mobileAndTabletCheck() ? 24 : 48}/>
                    <RotatingText text={'IN A QUEBEC BANK CALLED DESJARDINS'} font={'TG-Semibold'}
                                  isTextRendered={rotTextMontreal} delayIn={800} delayOut={0}
                                  fontSize={mobileAndTabletCheck() ? 24 : 48}/>
                    <RotatingText text={'AS A SPRINGBOOT & REACT DEVELOPER'} font={'TG-Semibold'}
                                  isTextRendered={rotTextMontreal} delayIn={800} delayOut={0}
                                  fontSize={mobileAndTabletCheck() ? 24 : 48}/>
                </div>
            </div>
            <div id="buttonContainer">
                <Button text="see other projects" handleClick={props.handleOnClickSeeProjects}/>
            </div>
        </div>
    );
};