import './button.scss';
import Marquee from "react-fast-marquee";
import {useState} from "react";

export interface ButtonProps {
    text: string;
    handleClick: () => any;
}

export const Button = (props: ButtonProps) => {

    const [isHover, setIsHover] = useState(false);

    const handleMouseHover = () => {
        setIsHover(true)
    }

    const handleMouseLeave = () => {
        setIsHover(false)
    }

    return (
        <div className="buttonContainer" onMouseEnter={handleMouseHover} onMouseLeave={handleMouseLeave} onClick={() => props.handleClick()}>
            <span style={{opacity: 0}}>{props.text.toUpperCase()}</span>
            <Marquee gradient={false} play={!isHover} speed={25} direction='right' className="marqueeContainer">
                <span>{props.text.toUpperCase()}</span>
                <span>{props.text.toUpperCase()}</span>
                <span>{props.text.toUpperCase()}</span>
            </Marquee>

        </div>
    );
};