import './rotatingStar.scss';
import {useEffect, useRef} from "react";
import starImg from "../../assets/img/star.png";

export interface ButtonProps {
}

export const RotatingStar = (props: ButtonProps) => {

    const imageRef = useRef<any>();

    useEffect(() => {
        window.addEventListener('scroll', () => {
            requestAnimationFrame(() => {
                if (imageRef && imageRef.current) {
                    imageRef.current.style.transform = "translate3d(0px, 0px, 0px) rotate(" + window.scrollY / 2 + "deg)";
                }
            });
        });
    }, []);

    return (
        <div className="rotatingStar">
            <img ref={imageRef}
                 src={starImg} width="30"
                 height="30" alt=""
                 role="presentation"/>
        </div>
    );
};