import './work-presentation.scss';
import angular from '../../assets/img/angular.json';
import react from '../../assets/img/react.json';
import javascript from '../../assets/img/javascript.json';
import circleSvg from '../../assets/img/circle.svg';
import arrowSvg from '../../assets/img/arrow.svg';
import Lottie from "react-lottie";

export interface WorkPresentationProps {
}

export const WorkPresentation = (props: WorkPresentationProps) => {

    const angularLottie = {
        loop: true,
        autoplay: true,
        animationData: angular,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const reactLottie = {
        loop: true,
        autoplay: true,
        animationData: react,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const javascriptLottie = {
        loop: true,
        autoplay: true,
        animationData: javascript,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <div className="workPresentationContainer">
            <div className="presentationContainer">
                <div className="presentation">
                    <h2>HELLO I AM NICO</h2>
                    <div className="name">Nico Moebs</div>
                </div>
                <div className="text container">
                    <div>I USE MY PASSION AND SKILLS</div>
                    <div>TO CREATE DIGITAL PRODUCTS AND</div>
                    <div>EXPERIENCES. NATIONAL AND</div>
                    <div>INTERNATIONAL CUSTOMERS RELY ON</div>
                    <div>ME FOR DESIGN, IMPLEMENTATION, AND</div>
                    <div>MANAGEMENT OF THEIR DIGITAL</div>
                    <div>PRODUCTS. AS AN INDEPENDENT, I WORK</div>
                    <div>ALSO WITH WEB AGENCIES, COMPANIES,</div>
                    <div>STARTUPS AND INDIVIDUALS TO CREATE</div>
                    <div>A BLUEPRINT FOR THE DIGITAL BUSINESS.</div>
                    <div>ADVISOR AND PARTNER OF SOME DIGITAL</div>
                    <div>AND FINTECH STARTUPS.</div>
                </div>
            </div>
            <div className="animationContainer" id="container">
                <div className="gridFrameworksContainer">
                    <div className="item">
                        <Lottie
                            options={angularLottie}
                            height={180}
                            width={140}
                        />
                    </div>
                    <div className="item">
                        <Lottie
                            options={reactLottie}
                            height={120}
                            width={120}
                        />
                    </div>
                    <div className="item">
                        <Lottie
                            options={javascriptLottie}
                            height={150}
                            width={150}
                        />
                    </div>
                </div>
                <div className="jokeContainer">
                    <div className="joke">
                        <div className="text">WHICH ONE DO YOU USE</div>
                        <img className="circle" src={circleSvg} alt=""/>
                        <img className="arrow" src={arrowSvg} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    );
}