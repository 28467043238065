import './availability.scss';
import {useEffect, useState} from "react";
import {RotatingStar} from "../../components/RotatingStar";

export interface AvailabilityProps {
    handleHoverAvailability: () => any;
    handleLeaveAvailability: () => any;
}

export const Availability = (props: AvailabilityProps) => {


    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');

    // eslint-disable-next-line
    useEffect(() => {
        const date = new Date();
        setDay(date.getDate().toString());
        setMonth(date.toLocaleString('fr-FR', {month: 'short'}));
    },  [])

    return (
        <div className="availabilityContainer" onMouseOver={props.handleHoverAvailability} onMouseLeave={props.handleLeaveAvailability}>
            <div className="scaling-svg">
                <RotatingStar />
            </div>
            <div className="day">{day}</div>
            <div className="rightContainer">
                <div className="month">{month}</div>
                <div className="availabaleForWork">
                    <span>available</span>
                    <span>for work</span>
                </div>
            </div>
        </div>
    );
};