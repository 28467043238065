import './rotating-text.scss';
import {useEffect, useState} from "react";

export class RotatingTextProps {
	text: string;
	font: string;
	isTextRendered = false;
	delayOut? = 0;
	delayIn? = 0;
	fontSize? = 24;
}

export const RotatingText = (props: RotatingTextProps) => {

	const yInitialPosition = -150;
	const rInitialPosition = 5;
	const YInitialPosition = 0;

	const xPosition = 0;
	const [yPosition, setYPosition] = useState(-150);
	const [rPosition, setRPosition] = useState(-5);
	const [opacity, setOpacity] = useState(0);

	useEffect(() => {
		setTimeout(() => {
			if (props.isTextRendered) {
				setYPosition(0);
				setRPosition(0);
				setOpacity(1);
			} else {
				setYPosition(yInitialPosition);
				setRPosition(rInitialPosition);
				setOpacity(YInitialPosition);
			}
			// eslint-disable-next-line
		}, props.isTextRendered ? props?.delayIn : props?.delayOut);
		// eslint-disable-next-line
	}, [props.isTextRendered]);

	return (
		<div className="rotatingTextContainer" style={{transition: 'all 0.8s ease-out', opacity: opacity, lineHeight: `${props.fontSize}px`}}>
			<div className="rotation" style={{transition: 'all 0.5s ease-in-out', fontFamily: props.font,
				transform: `translate(${xPosition}, ${yPosition}%) rotate(${rPosition}deg)`, fontSize: `${props.fontSize}px`}}>{props.text}</div>
		</div>
	);
};