import './menu.scss';
import { v4 as uuidv4 } from 'uuid';

export interface MenuProps {
    refs: {label: string, id: string}[];
}

export const Menu = (props: MenuProps) => {

    const scrollToComponent = (ref: {label: string, id: string}) => {
        const yOffset = -100;
        const anchor = document.querySelector(`#${ref.id}`);
        const y = anchor.getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
    }

    return (
        <div className="menuContainer">
            {props.refs.map(ref => {
                return <div className="un" key={uuidv4()} onClick={() => scrollToComponent(ref)}>{ref.label}</div>
            })}
        </div>
    );
};