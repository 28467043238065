import './panel-projects.scss';
import x from '../../assets/img/x.svg';
import { v4 as uuidv4 } from 'uuid';

export interface PanelProjectsProps {
    handleCloseWindow : () => any;
}

interface Project {
    title: string;
    date: string;
    description: string;
}

export const PanelProjects = (props: PanelProjectsProps) => {

    const projects: Project[] = [
        {
            title: 'BATICA',
            date: '2022',
            description: 'SPRINGBOOT - ANGULAR 7'
        },
        {
            title: 'PRISE DE RDV',
            date: '2021 - 2022',
            description: 'SPRINGBOOT - REACT'
        },
        {
            title: 'SOFINCO',
            date: '2021',
            description: 'VUEJS - NODEJS'
        },
        {
            title: 'BATICA',
            date: '2020',
            description: 'SPRINGBOOT - ANGULAR 7'
        },
        {
            title: 'DIGICONSO',
            date: '2020',
            description: 'VUEJS'
        },
        {
            title: 'LCTOUT',
            date: '2019',
            description: 'REACT - ELASTICSEARCH'
        },
        {
            title: 'KYR',
            date: '2019',
            description: 'ANGULAR 7'
        },
        {
            title: 'BATICA',
            date: '2018',
            description: 'SPRINGBOOT - ANGULAR 4'
        },
        {
            title: 'VOICEBOT',
            date: '2018',
            description: 'ANGULAR 4 - IONIC'
        },
        {
            title: 'EDELIA',
            date: '2017',
            description: 'SPRINGBOOT'
        }
    ];

    return (
        <div className="panelProjectsContainer">
            <h3>SELECTED PROJECTS SINCE I STARTED WORIKING 2018 - 2022</h3>
            <img src={x} alt="close" onClick={props.handleCloseWindow}/>
            <ul>
                {
                    projects.map(project => {
                        return <li key={uuidv4()}>
                            <span className="text">{project.title}</span>
                            <span className="text">{project.description}</span>
                            <span className="text date">{project.date}</span>
                        </li>;
                    })
                }
            </ul>
        </div>
    );
};