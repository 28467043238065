import './header.scss';
import React from "react";
import {Menu} from "./Menu";

export interface HeaderProps {
	refs: {label: string, id: string}[];
}

// @ts-ignore
export const Header = (props: HeaderProps) => {
	
	return (
		<div className="headerContainer">
			<div className="name">Nico Moebs</div>
			<div className="job">FULLSTACK DEVELOPER</div>
			<div className="menu">
				<Menu refs={props.refs}/>
			</div>
		</div>
	);
};
