import React, {useEffect, useRef, useState} from 'react';
import './App.scss';
import backgroundPicture from './assets/img/fleurs.jpg';
import {Header} from "./features/Header";
import {Availability} from "./features/Availability";
import {Description} from "./features/Description";
import {WorkPresentation} from "./features/WorkPresentation";
import {PanelProjects} from "./features/PanelProjects";
import {Contact} from "./features/Contact";
import {mobileAndTabletCheck} from "./utils";
import {WorkCities} from "./features/WorkCities";

function App() {

    const imgBgRef = useRef<any>();
    const backgroundContainerRef = useRef<any>();
    const descriptionRef: {label: string, id: string} = {
        id: 'descriptionId',
        label: 'description,'
    };
    const presentationRef: {label: string, id: string} = {
        id: 'presentationId',
        label: 'presentation,'
    };
    const citiesRef: {label: string, id: string} = {
        id: 'citiesId',
        label: 'cities,'
    };
    const contactRef: {label: string, id: string} = {
        id: 'contactId',
        label: 'contact'
    };

    const [opacityHeader, setOpacityHeader] = useState(1);
    const [topHeader, setTopHeader] = useState(0);
    const [opacityBg, setOpacityBg] = useState(mobileAndTabletCheck() ? 0 : 1);
    const [translationBackground, setTranslationBackground] = useState(0);
    const [rightPanelJobs, setRightPanelJobs] = useState(-100);
    const [clipPathValue, setClipPathValue] = useState(0);
    const body = useState(document.body)[0];

    const [opacityAllEl, setOpacityAllEl] = useState(1);

    // eslint-disable-next-line
    useEffect(() => {
        if (window) {
            window.onscroll = () => {
                let currentScrollPos = window.scrollY;
                let value = currentScrollPos / 400;

                setOpacityHeader(value * 1.7);

                if (currentScrollPos < 400 && !mobileAndTabletCheck()) {
                    setTranslationBackground(-currentScrollPos * 0.6)
                }

                const html = document.documentElement;

                const height = Math.max( body.scrollHeight, body.offsetHeight,
                    html.clientHeight, html.scrollHeight, html.offsetHeight );

                if (currentScrollPos < (height - 1000)) {
                    setOpacityBg(2 - value);
                }

                if ((window.innerHeight + Math.ceil(window.scrollY)) >= body.offsetHeight) {
                    setOpacityBg(value);
                }

                if (imgBgRef && imgBgRef.current) {
                    imgBgRef.current.style.transform =  `translate(0px, ${currentScrollPos}px, 0px)`;
                }

            }
        }
        setClipPathValue(100);
        // eslint-disable-next-line
    }, []);

    const handleHoverAvailability = () => {
        if (imgBgRef && imgBgRef.current) {
            imgBgRef.current.style.clipPath =  "circle(20% at 50% 40%)";
        }
    }

    const handleLeaveAvailability = () => {
        if (imgBgRef && imgBgRef.current) {
            imgBgRef.current.style.clipPath =  null;
        }
    }

    const handlePictureOpening = (city: string) => {
        // setTimeout(() => 1, 800);
        // setOpacityBg(prev => {
        //     console.log(prev <= 0 ? 0.4 : 0)
        //     return prev >= 0 ? 0.4 : 0
        // });
        opacityOnAll();
    }

    const handleOnClickSeeProjects = () => {
        rightPanelJobs === 0 ? body.style.overflowY = 'overlay' : body.style.overflowY = 'hidden';
        setRightPanelJobs((prevState) => (prevState !== 0 ? 0 : -100));
        setTopHeader((prevState) => (prevState !== 0 ? 0 : -100));
    }

    const opacityOnAll = () => {
        if (opacityAllEl) {
            body.style.overflowY = 'hidden';
            setOpacityAllEl(0);
        } else {
            body.style.overflowY = 'overlay';
            setOpacityAllEl(1);
        }
    }

    return (
        <div className="app">
            <header className="app-header-container" style={{top: topHeader}}>
                <div className="app-header">
                    <Header refs={[descriptionRef, citiesRef, contactRef]}/>
                </div>
                <div className="scroller" style={{opacity: `${opacityHeader}`}}></div>
            </header>
            <div id="not-supported">
                <div className="line">Orientation</div>
                <div className="line">not supported</div>
            </div>
            <main>
                <div ref={backgroundContainerRef}
                     className="appBackgroundContainer"
                     style={{transform: `translate3d(0px, ${translationBackground}px, 0px)`, opacity: `${opacityBg}`, clipPath: `circle(${clipPathValue}%)` }}
                >
                    <img ref={imgBgRef}
                         className="appBackgroundImg"
                         alt=""
                         role="presentation"
                         src={backgroundPicture} />
                </div>
                <div className="mainContainer">
                    <div className="mainAvailability" style={{transition: 'opacity 1s', opacity: opacityAllEl}}>
                        <Availability
                            handleHoverAvailability={handleHoverAvailability}
                            handleLeaveAvailability={handleLeaveAvailability} />
                    </div>
                    <div className="mainDescription container" id={descriptionRef.id} style={{transition: 'opacity 1s', opacity: opacityAllEl}}>
                        <Description />
                    </div>
                    <div className="mainPresentation container" id={presentationRef.id} style={{transition: 'opacity 1s', opacity: opacityAllEl}}>
                        <WorkPresentation />
                    </div>
                    <div className="mainPresentation container" id={citiesRef.id}>
                        <WorkCities handlePictureOpening={handlePictureOpening} handleOnClickSeeProjects={handleOnClickSeeProjects}/>
                    </div>
                </div>
                <div className="panelContainer" style={{right: `${rightPanelJobs}%`}}>
                    <div className="panelC">
                        <div className="outside" onClick={handleOnClickSeeProjects}></div>
                        <div className="panel">
                            <PanelProjects handleCloseWindow={handleOnClickSeeProjects}></PanelProjects>
                        </div>
                    </div>
                </div>
                <div className="contactContainer" id={contactRef.id} style={{transition: 'opacity 1s', opacity: opacityAllEl}}>
                    <Contact />
                </div>
            </main>
        </div>
    );
}

export default App;
